export const navLinks = [
  {
    id: 1,
    title: 'Overview',
    anchor: 'header',
  },
  {
    id: 2,
    title: 'How It Works',
    anchor: 'how-it-works',
  },
  {
    id: 3,
    title: 'Use cases',
    anchor: 'use-cases',
  },
  {
    id: 4,
    title: 'Benefits',
    anchor: 'benefits',
  },
  {
    id: 5,
    title: 'Try it out',
    anchor: 'try-it-out',
  },
  // {
  //   id: 6,
  //   title: 'Meet Taras',
  //   anchor: 'meet-contact',
  // },
  {
    id: 7,
    title: "Let's talk",
    anchor: 'contact-us',
  },
];
