import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';

import ReusableCarousel from '../ReusableCarousel/ReusableCarousel';

import { data } from './content';

import './Carousel.scss';

const StyledCarousel = () => {
  return (
    <div id="use-cases" className="carousel-section">
      <div className="carousel-section__content">
        <p className="carousel-section__name">{data.name}</p>
        <h2 className="carousel-section__heading">{data.title}</h2>
        {/* <div className="carousel-with-controls">
          <button className="carousel-slider__button carousel-slider__button-left" type="button" onClick={prevSlide}>
            <img src={chevron_left} />
          </button>
          <button className="carousel-slider__button carousel-slider__button-right" type="button" onClick={nextSlide}>
            <img src={chevron_right} />
          </button>
          <Carousel
            onChange={updateCurrentSlide}
            selectedItem={currentSlide}
            statusFormatter={(current, total) => `Current slide: ${current} / Total: ${total}`}
            className="carousel-section__carousel"
            infiniteLoop
            showIndicators={false}
            showThumbs={false}
            showStatus={false}
            showArrows={false}
          >
            {sliderItems.map(({ title, image, description }) => (
              <div key={title} className="carousel-slider__slide">
                <img className="carousel-slider__image" src={image} />
                <div className="carousel-slider__text">
                  <h3 className="carousel-slider__heading">{title}</h3>
                  <p className="carousel-slider__description">{description}</p>
                </div>
              </div>
            ))}
          </Carousel>
        </div> */}
        <ReusableCarousel sliderItems={data.items} />
      </div>
    </div>
  );
};

export default StyledCarousel;
