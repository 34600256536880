import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';

import chevron_left from '../../../asset/resource/chevron-left.svg';
import chevron_right from '../../../asset/resource/chevron-right.svg';

import './ReusableCarousel.scss';

const ReusableCarousel = ({ sliderItems, isUploadImageSection, setSlideIdx }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const prevSlide = () => {
    setCurrentSlide(currentSlide - 1);
  };

  const nextSlide = () => {
    setCurrentSlide(currentSlide + 1);
  };
  const updateCurrentSlide = (idx) => {
    setCurrentSlide(idx);

    if (isUploadImageSection) {
      setSlideIdx(idx);
    }
  };

  return (
    <div className="carousel-with-controls">
      <button
        aria-label="Previous slide"
        className="carousel-slider__button carousel-slider__button-left"
        type="button"
        onClick={prevSlide}
      >
        <img alt="" src={chevron_left} />
      </button>
      <button
        aria-label="Next slide"
        className="carousel-slider__button carousel-slider__button-right"
        type="button"
        onClick={nextSlide}
      >
        <img alt="" src={chevron_right} />
      </button>
      <Carousel
        onChange={updateCurrentSlide}
        selectedItem={currentSlide}
        statusFormatter={(current, total) => `Current slide: ${current} / Total: ${total}`}
        className="carousel-section__carousel"
        infiniteLoop
        showIndicators={false}
        showThumbs={false}
        showStatus={false}
        showArrows={false}
        swipeable={true}
        swipeScrollTolerance={15}
      >
        {sliderItems.map(({ title, image, description }) => (
          <div key={image} className="carousel-slider__slide">
            <img
              alt=""
              className={`carousel-slider__image ${isUploadImageSection ? 'upload-section__example-image' : ''}`}
              src={image}
            />
            {(title || description) && (
              <div className="carousel-slider__text">
                <h3 className="carousel-slider__heading">{title}</h3>
                <p className="carousel-slider__description">{description}</p>
              </div>
            )}
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default ReusableCarousel;
