import DOMPurify from 'isomorphic-dompurify';

export function sanitizeHtml(html) {
  let cleanedData = DOMPurify.sanitize(html);
  return cleanedData;
}

export function millisToMinutesAndSeconds(millis) {
  var minutes = Math.floor(millis / 60000);
  var seconds = ((millis % 60000) / 1000).toFixed(0);
  return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
}

export function numbersAnimation(className) {
  const alphabet = '1234567890ABCDEFGHIJKLMNOPQRSTUVWZYZ-';

  //fix typo
  let iterations = 0;
  const element = document.getElementsByClassName(className)[0];

  if (!element) return;

  setTimeout(() => {
    const interval = setInterval(() => {
      element.textContent = element.textContent
        .split('')
        .map((_, index) => {
          if (index < iterations) {
            return element.dataset.value[index];
          }
          return alphabet[Math.floor(Math.random() * 26)];
        })
        .join('');

      if (iterations > 9) clearInterval(interval);
      iterations += 1 / 3;
    }, 100);
  }, 1600);
}
