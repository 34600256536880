import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useCheckMobile } from '../../hooks';

import './Benefits.scss';

import { data, desktopImages, mobileImages } from './content';

const AnimationsFamily = (idx, is2k) => {
  switch (idx) {
    case 0:
      return (
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 2, delay: 1 }}
          variants={{
            visible: { top: '55%', left: '35%', scale: 1.3, opacity: 1 },
            hidden: { top: '55%', left: '15%', scale: 0.1, opacity: 0 },
          }}
          className="benefits-section__circle benefits-section__first-animation"
        />
      );
    case 1:
      return (
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 3 }}
          variants={{
            visible: { bottom: '105%', right: '-3%' },
            hidden: { bottom: '5%', right: '100%' },
          }}
          className="benefits-section__circle benefits-section__second-animation"
        />
      );
    case 2:
      return (
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 2 }}
          variants={{
            visible: { opacity: 1, top: `calc(45% - ${is2k ? '97px' : '67px'})`, right: '-25%' },
            hidden: { opacity: 0, top: `calc(45% - ${is2k ? '97px' : '67px'})`, right: '100%' },
          }}
          className="benefits-section__circle benefits-section__third-animation"
        />
      );
    case 3:
      return (
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 2 }}
          //calc(45% - ${is2k ? '97px' : '67px'})
          variants={{
            visible: { opacity: 1, top: `-15%`, left: '15.5%' },
            hidden: { top: '75%', left: '15.5%' },
          }}
          className="benefits-section__circle benefits-section__fourth-animation"
        />
      );
    default:
      return;
  }
};

const renderImage = (idx, image, is2k) => {
  switch (idx) {
    case 0:
      // return <img className="benefits-section__image" src={image} />;
      return (
        <motion.img
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 2 }}
          variants={{
            // visible: { width: is2k ? `${375 * 1.44}px` : '410px', height: is2k ? `${161 * 1.44}px` : '151px' },
            // hidden: { width: is2k ? `${140 * 1.44}px` : '0px', height: is2k ? `${120 * 1.44}px` : '141px' },

            visible: { width: '100%' },
            hidden: { width: '10%' },
          }}
          style={{ objectPosition: 'left', objectFit: 'contain' }}
          className="benefits-section__image benefits-section__image_first"
          src={image}
          alt=""
        />
      );
    case 1:
      return <img className="benefits-section__image" src={image} />;
    case 2:
      return <img className="benefits-section__image" src={image} />;
    case 3:
      return (
        <>
          <motion.img style={{ visibility: 'hidden' }} className="benefits-section__image" src={image} />
          <motion.img
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 2 }}
            variants={{
              visible: { height: '100%', opacity: 1 },
              hidden: { height: '0px', opacity: 0 },
            }}
            style={{ position: 'absolute' }}
            className="benefits-section__image"
            src={image}
          />
        </>
      );
  }
};

const Benefits = () => {
  const [is2k, setIs2k] = useState(false);
  const isMobile = useCheckMobile({ width: 1024 });
  // const is2k = useCheckMobile({ width: 2560 });

  const handleResizer = () => {
    if (window.innerWidth >= 2560) {
      setIs2k(true);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResizer);

    return () => {
      window.removeEventListener('resize', handleResizer);
    };
  }, []);

  const items = data.items.map((item, idx) => ({
    ...item,
    image: isMobile ? mobileImages[idx] : desktopImages[idx],
  }));

  return (
    <section id="benefits" className="benefits-section">
      <div className="benefits-section__content">
        <p className="benefits-section__section-type">{data.name}</p>
        <h2 className="benefits-section__section-heading">{data.title}</h2>
        <div className="benefits-section__items">
          {items.map((item, idx) => (
            <div key={item.title} className="benefits-section__item">
              <div className={`benefits-section__image-wrapper benefits-section__image-wrapper_${idx + 1}-animated`}>
                {!isMobile ? AnimationsFamily(idx, is2k) : null}
                {isMobile ? null : renderImage(idx, item.image, is2k)}
              </div>
              {isMobile ? <img className="benefits-section__image" src={item.image} /> : null}
              <div className="benefits-section__text-wrapper">
                {/* <p className="benefits-section__item-number">{item.number}</p> */}
                <h3 className="benefits-section__item-title">{item.title}</h3>
                <p className="benefits-section__item-description">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Benefits;
