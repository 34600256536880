import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { Link as ScrollLink } from 'react-scroll';
import { sanitizeHtml, numbersAnimation } from '../../utils';

import { useCheckMobile } from '../../hooks';

import hero_car from '../../../asset/resource/hero-car.png';
import hero_car_with_border from '../../../asset/resource/hero-car-with-border.png';

import { data } from './content';

import './Hero.scss';

export const Hero = () => {
  const is2k = useCheckMobile({ width: 2560 });
  const isMobile = useCheckMobile({ width: 640 });
  const [containerHeight, setContainerHeight] = useState();
  const imageRef = useRef();

  const handleResize = () => {
    setContainerHeight(imageRef.current.clientHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div id="header" className="hero-section">
      <div className="hero-section__wrapper">
        <div className="hero-section__content">
          <h1 className="hero-section__heading" dangerouslySetInnerHTML={{ __html: sanitizeHtml(data.title) }}></h1>
          <h2 className="hero-section__sub-heading">{data.powered}</h2>
          <p className="hero-section__description">{data.description}</p>

          <ScrollLink
            smooth
            className="hero-section__btn hero-section__btn_mobile"
            to="contact-us"
            offset={isMobile ? -65 : -90}
            duration={800}
          >
            {data.ctaText}
          </ScrollLink>
          <img src={isMobile ? hero_car_with_border : hero_car} className="hero-section__hero-car-mobile" />
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 1.5, type: 'tween' }}
            variants={{
              visible: { bottom: '-20%', right: '12%', opacity: 1, scale: is2k ? 1 : 0.8 },
              hidden: { bottom: '-80%', right: '15%', opacity: 1, scale: 1.2 },
            }}
            ref={imageRef}
            className="hero-section__hero-car"
          >
            <motion.span
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              style={{ height: containerHeight }}
              transition={{ duration: 0.4, delay: 1 }}
              variants={{
                visible: { opacity: 1, scale: 1 },
                hidden: { opacity: 0.8, scale: 1.2 },
              }}
              className="hero-section__animation-anchor"
            ></motion.span>
            <motion.p
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.4, delay: 1.5 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 },
              }}
              onLoad={numbersAnimation('animated-numbers')}
              className="animated-numbers"
              data-value={data.plateNumber}
            >
              {data.plateNumber}
            </motion.p>
            <img alt="car moving on road" src={hero_car} />
          </motion.div>
        </div>
        <div className="hero-section__additional-description-wrapper">
          <div className="hero-section__additional-description">
            <p>{data.additionalDescription_1}</p>
            <p>{data.additionalDescription_2}</p>
          </div>
          <ScrollLink smooth className="hero-section__btn" to="contact-us" offset={isMobile ? -65 : -90} duration={800}>
            {data.ctaText}
          </ScrollLink>
        </div>
      </div>
    </div>
  );
};

export default Hero;
