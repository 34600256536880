import React, { useEffect } from 'react';

import './ReportSection.scss';

const ReportSection = ({ size }) => {
  useEffect(() => {
    let area;

    setTimeout(() => {
      area = document.getElementsByClassName('displayArea')[0];
    }, 5500);

    if (!area) return;
    area.style.setProperty('top', '0px', 'important');
  });
  return (
    <div className="report-section__wrapper">
      <div className="report-section__content">
        <h2 className="report-section__title">Scroll down to explore what an analytical report can look like</h2>
        <iframe
          title="Report Section"
          width={size.width}
          height={size.height}
          src="https://app.powerbi.com/view?r=eyJrIjoiYjEzYzk5MzEtNzNlMS00MTNjLTlmYjgtZDI4NWY5YzFmN2U0IiwidCI6IjZjNTFjNjU5LTlkNTItNDFhZi04MWY3LWRkZTE2MzgwZTgxMyIsImMiOjl9"
          frameBorder="0"
          allowFullScreen={true}
        />
      </div>
    </div>
  );
};

export default ReportSection;
