import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';

import UploadImageLoader from '../UploadImageLoader/UploadImageLoader';
import ReusableCarousel from '../ReusableCarousel/ReusableCarousel';
import Response from './components/Response/Response';
import { example_1, example_2, example_3 } from './decodedExamples';

import { useCheckMobile } from '../../hooks';

import { uploadIcon } from './resources';
import { data } from './content';

import './ImageUpload.scss';

const ImageUpload = () => {
  const [encodedFile, setEncodedFile] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [result, setResult] = useState(false);
  const [isExampleSlider, setExampleSlider] = useState(false);

  const [isClickAllowed, setClickAllowed] = useState(false);
  const [slideIdx, setSlideIdx] = useState(0);

  const isMobile = useCheckMobile({ width: 640 });

  function calculateWindowView() {
    return setExampleSlider(window.innerWidth <= 640);
  }

  useEffect(() => {
    calculateWindowView();
    window.addEventListener('resize', calculateWindowView);

    return () => {
      window.removeEventListener('resize', calculateWindowView);
    };
  }, []);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg'],
    },
    disabled: isLoading,
    noClick: !isClickAllowed,
    onDrop: (acceptedFiles) => {
      acceptedFiles.map((acceptedFile) => {
        handleDrop(acceptedFile);
      });
    },
  });

  const sendImageToLambda = async (file) => {
    const url = 'http://lpc-videoanalytics.azurewebsites.net/detect/image';
    const clearedEncodedFile = file.split(',')[1];

    if (!clearedEncodedFile) return;

    setLoading(true);
    try {
      const response = await axios.post(url, { type: 'full', image: clearedEncodedFile });

      if (response.data.processed) {
        const carplateNumber = Object.keys(response.data.result)[0];
        setResult({ text: carplateNumber, croppedNumberPlate: carplateNumber });
        setError(null);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setResult(null);
      setLoading(false);
      setError(true);
    }
  };

  useEffect(() => {
    if (!encodedFile) return;
    sendImageToLambda(encodedFile);
  }, [encodedFile]);

  const selectFromExamples = async (idx, isFromUploadImage) => {
    let innerIdx = idx;
    if (isLoading) return;
    const canvas = document.createElement('canvas');

    if (isFromUploadImage) {
      /*
        since Carousel created two additional slides with images 
        we need to make a quick fix to get the CORRECT image for uploading
      */
      innerIdx = idx === 0 ? 1 : idx === 4 ? 0 : idx === 2 ? 3 : idx;
    }

    /* canvas encoding is not vaid for this example, so encoded file saved as constant */
    if (idx === 0) {
      setEncodedFile(example_1);
    } else if (idx === 1) {
      setEncodedFile(example_2);
    } else if (idx === 2) {
      setEncodedFile(example_3);
    } else {
      const selected = document.getElementsByClassName('upload-section__example-image')[innerIdx];

      canvas.height = selected.naturalHeight;
      canvas.width = selected.naturalWidth;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(selected, 0, 0, canvas.width, canvas.height);

      const base64String = canvas.toDataURL('image/*');
      setEncodedFile(base64String);

      let uploadSection;
      if (!isMobile) {
        uploadSection = document.getElementById('upload-window');
      } else {
        uploadSection = document.getElementById('upload-mobile');
      }
    }
  };

  const changeState = (base64String) => {
    setEncodedFile(base64String);
  };

  const updateIdxFromChild = (idx) => {
    setSlideIdx(idx);
  };

  useEffect(() => {
    let uploadSection;
    if (isMobile) {
      uploadSection = document.getElementById('upload-mobile');
      setTimeout(() => {
        uploadSection.scrollIntoView({
          behavior: 'smooth',
          block: (result && 'end') || (error && 'center') || 'center',
          inline: 'end',
        });
      }, 0);
    } else {
      uploadSection = document.getElementById('desktop-scroll-anchor');
      if (!result) {
        setTimeout(() => {
          uploadSection.scrollIntoView({
            behavior: 'smooth',
            block: (isLoading && 'center') || (result && 'center') || 'center',
            inline: 'end',
          });
        }, 0);
      } else {
        uploadSection = document.getElementById('desktop-scroll-result-anchor');
        setTimeout(() => {
          uploadSection.scrollIntoView({
            behavior: 'smooth',
            block: ((error || result) && 'start') || 'center',
            inline: 'end',
          });
        }, 0);
      }
    }
  }, [isLoading, error, result, isMobile]);

  const handleDrop = (acceptedFile) => {
    setLoading(true);

    const file = acceptedFile;

    const reader = new FileReader();
    reader.onloadend = function () {
      if (encodedFile && encodedFile === reader.result) {
        setLoading(false);
        return;
      }

      setEncodedFile(reader.result);
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ y: 0 });
    }, 0);
  }, []);

  return (
    <div id="try-it-out" className="upload-section">
      <div className="upload-section__content">
        <p className="upload-section__name">{data.name}</p>
        <p className="upload-section__sneak">{data.sneak}</p>

        <div className="upload-section__examples">
          <h2 className="upload-section__examples-title">{data.examples.title}</h2>
          {!isExampleSlider && (
            <div className="upload-section__examples-items">
              {data.examples.items.map(({ image, ctaText }, idx) => (
                <div key={idx} className="upload-section__example-item">
                  <img className="upload-section__example-image" src={image} />
                  <button
                    disabled={isLoading}
                    onClick={() => selectFromExamples(idx)}
                    className="upload-section__example-button"
                  >
                    {ctaText}
                  </button>
                </div>
              ))}
            </div>
          )}

          {isExampleSlider && (
            <div className="upload-section__examples-items_mobile">
              <ReusableCarousel
                sliderItems={data.examples.items}
                isUploadImageSection
                uploadHandler={changeState}
                setSlideIdx={updateIdxFromChild}
                isLoading={isLoading}
              />
              <button
                disabled={isLoading}
                className="upload-section__examples-items_mobile__button"
                onClick={() => selectFromExamples(slideIdx, true)}
              >
                {data.examples.mobileCtaText}
              </button>
            </div>
          )}
        </div>

        {!isMobile && (
          <div id="upload-window" className="user-upload-section">
            <input
              id="upload"
              {...getInputProps()}
              style={{ display: 'none' }}
              className="image-uploader"
              placeholder="Upload Image"
              accept="image/jpeg, image/png, image/jpg"
              alt="image uplaoder"
            />
            <div
              id="desktop-scroll-result-anchor"
              className={`user-upload-section__text user-upload-section__text_${
                isLoading ? 'loading' : ''
              } user-upload-section__text_${result?.text ? 'has-result' : ''}`}
            >
              <p className="user-upload-section__title">{data.title}</p>
              <p className="user-upload-section__description">{data.description}</p>
            </div>
            <div id="upload-section-with-response" className="user-upload-section__with-response">
              {encodedFile && <img className="desktop-view" src={encodedFile} />}
              <div className="user-upload-section__with-response__right-col">
                {result?.text && <p className="user-upload-section__with-response-title">{data.result.title}</p>}
                {result?.text && (
                  <div className="user-upload-section__with-response__response">
                    <div
                      className={`user-upload-section__with-response__details user-upload-section__with-response__details${
                        isLoading ? '_loading' : ''
                      }`}
                    >
                      {/* INFO: hidden because API doesn't return cropped image number */}
                      {/* <img src={result.croppedNumberPlate} /> */}
                      <p className={`${isLoading ? 'numbers-plate_loading' : ''}`}>{result.text}</p>
                    </div>
                    {/* INFO: hidden because API doesn't return cropped image number */}
                    {/* {<img className="tablet-view" src={result.image} />} */}
                  </div>
                )}
                <div
                  {...getRootProps({
                    className: `user-upload-section__area user-upload-section__area_${
                      result?.text ? 'has-result' : ''
                    }`,
                  })}
                >
                  <div style={{ margin: isLoading ? '0' : '20px' }} className="user-upload-section__area-content">
                    <button
                      id="desktop-scroll-anchor"
                      onMouseEnter={() => setClickAllowed(true)}
                      onMouseLeave={() => setClickAllowed(false)}
                      htmlFor="upload"
                      className={`user-upload-section__btn user-upload-section__btn_${isLoading ? 'loading' : ''}`}
                    >
                      {isLoading ? (
                        <>
                          <UploadImageLoader />
                          <span>{data.loading.status}</span>
                        </>
                      ) : (
                        <>
                          <img src={uploadIcon} />
                          <span>{result?.text ? data.result.ctaRepeatText : data.result.ctaText}</span>
                        </>
                      )}
                    </button>
                    {isLoading ? (
                      <p>
                        <span className="user-upload-section__loading-text">{data.loading.title}</span>
                      </p>
                    ) : (
                      <Response error={error} result={result} />
                    )}
                  </div>
                </div>
                <p className="user-upload-section__legal-info">
                  The uploaded image will not be stored, shared, or retained in any way, and it will be promptly deleted
                  after the image processing is completed.
                </p>
              </div>
            </div>
          </div>
        )}

        <div id="upload-window" className="mobile-upload">
          {result?.text && (
            <div className="mobile-upload__result">
              <p className="mobile-upload__result-title">{data.result.title}</p>
              <div className="mobile-upload__result-details">
                {/* INFO: hidden because API doesn't return cropped image number */}
                {/* <img src={result.croppedNumberPlate} /> */}
                <p className={`${isLoading ? 'numbers-plate_loading' : ''}`}>{result?.text}</p>
              </div>
              <img className="mobile-upload__result-image" src={encodedFile} />
            </div>
          )}
          <input
            id="upload"
            {...getInputProps()}
            style={{ display: 'none' }}
            className="image-uploader"
            placeholder="Upload Image"
            accept="image/jpeg, image/png, image/jpg"
            alt="image uplaoder"
          />
          <div
            className={`user-upload-section__text user-upload-section__text_${
              isLoading ? 'loading' : ''
            } user-upload-section__text_${result?.text ? 'has-result' : ''}`}
          >
            {!error && !result?.text ? (
              <>
                <p className="user-upload-section__title">{data.title}</p>
                <p className="user-upload-section__description">{data.description}</p>
              </>
            ) : (
              <>
                <Response error={error} result={result} />
              </>
            )}
          </div>
          <label
            id="upload-mobile"
            htmlFor="upload"
            className={`
            user-upload-section__btn 
            user-upload-section__btn_${isLoading ? 'loading' : ''} 
            user-upload-section__btn_${result?.text ? 'has-result' : ''}`}
          >
            {isLoading ? (
              <>
                <UploadImageLoader />
                <span>{data.loading.status}</span>
              </>
            ) : (
              <>
                {result?.text || error ? null : <img src={uploadIcon} />}
                <span>{result?.text || error ? data.result.ctaRepeatText : data.result.ctaText}</span>
              </>
            )}
          </label>
          <p
            className={`user-upload-section__legal-info user-upload-section__legal-info_${
              result?.text ? 'has-result' : 'no-result'
            }`}
          >
            The uploaded image will not be stored, shared, or retained in any way, and it will be promptly deleted after
            the image processing is completed.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ImageUpload;
