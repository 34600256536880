import image_1 from '../../../asset/resource/upload-example-1.jpeg';
import image_2 from '../../../asset/resource/upload-example-2.png';
import image_3 from '../../../asset/resource/upload-example-3.jpeg';
import cloud from '../../../asset/resource/cloud-icon.png';
import upload_icon from '../../../asset/resource/upload-btn-icon.png';

export const uploadItems = [
  {
    image: image_1,
  },
  {
    image: image_2,
  },
  {
    image: image_3,
  },
];

export const cloudImage = cloud;
export const uploadIcon = upload_icon;
