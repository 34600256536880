import hiw_icon_1 from '../../../asset/resource/hiw-icon-1.svg';
import hiw_icon_2 from '../../../asset/resource/hiw-icon-2.svg';
import hiw_icon_3 from '../../../asset/resource/hiw-icon-5.svg';
import hiw_icon_4 from '../../../asset/resource/hiw-icon-4.svg';
import hiw_icon_5 from '../../../asset/resource/hiw-icon-3.svg';

export const data = {
  name: 'How it works',
  title: 'KEY FEATURES',
  bottomText: "The platform's features can be customized to meet the customers' needs and requirements.",
  items: [
    {
      image: hiw_icon_1,
      number: 'Item 1',
      title: 'LICENSE PLATE RECOGNITION',
      description:
        'Read, identify, and verify vehicle registration plates captured on photo or video from surveillance cameras. The system can handle unclear, dim, or slanted plates and works seamlessly regardless of whether or not the cameras have a built-in recognition feature.',
    },
    {
      image: hiw_icon_2,
      number: 'Item 2',
      title: 'DATABASE CONNECTION',
      description:
        'Link the software to the databases and watchlists to access detailed information about the vehicles upon request. Data retrieval happens in milliseconds, while the well-structured APIs pave the way for seamless interaction with obtained data.',
    },
    {
      image: hiw_icon_3,
      number: 'Item 3',
      title: 'ADVANCED ANALYTICS',
      description:
        'Track car location, speed, and route, and identify fake license plates or missing cars. Collect data sets and explore them in great depth to uncover road traffic patterns. Make fact-based predictions and well-informed decisions.',
    },
    {
      image: hiw_icon_4,
      number: 'Item 4',
      title: 'SMART NOTIFICATION SYSTEM',
      description:
        'Fine-tune notifications to get real-time alerts on stolen and wanted cars, or particular incidents. Specify the model of the vehicle, its color, license plate, and other details according to your requirements.',
    },
    {
      image: hiw_icon_5,
      number: 'Item 5',
      title: 'INTERACTIVE REPORTING',
      description:
        'Utilize the power of the Microsoft Power BI report that centralizes gathered data and translates it into actionable insights. Uncover the full potential of your data with rich and interactive visualization tools.',
    },
  ],
};
