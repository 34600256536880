import React, { useState } from 'react';
import RegistrationForm from '../RegistrationForm/RegistrationForm';

import './FormSection.scss';

const FormSection = () => {
  const [formStatus, setFormStatus] = useState(null);

  return (
    <section id="contact-us" className="form-section">
      <div className="form-section__content">
        <h2 className="form-section__title">
          Let's <br className="tablet-hidden" /> work <span>together</span>
        </h2>

        <div className="form-section__form-container">
          {!formStatus && (
            <>
              <p className="form-section__subtitle">Request a demo</p>
              <p className="form-section__description">
                Would you like to learn how License Plate Recognition Video Analytics can assist in meeting your goals?{' '}
                <br />
                <br /> Fill in the form below. We'll get back to you.
              </p>
            </>
          )}
          <RegistrationForm updateFormStatus={setFormStatus} />
        </div>
      </div>
    </section>
  );
};

export default FormSection;
