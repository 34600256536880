import React from 'react';

const renderErrorMessage = (error) => {
  return (
    <p className={`${error ? 'user-upload-section__error' : ''}`}>
      {!error ? 'or drag and drop it here' : 'photo was not recognized'}
      <br />
      <span>
        {!error ? (
          <>(PNG, JPG or JPEG files up to 30MB)</>
        ) : (
          `The license plate number in this image is not clear enough for accurate recognition. 
            Please upload a more detailed image for accurate plate number verification.`
        )}
      </span>
    </p>
  );
};

const Response = ({ error }) => {
  return (
    <div>
      <p className="user-upload-section__result-title">YOUR RESULT</p>
      {renderErrorMessage(error)}
    </div>
  );
};

export default Response;
