export const data = {
  title: `License Plate <br /> Recognition Video <br /> Analytics`,
  description: `On the first days of the full-scale invasion, Ukraine, shaken but united, mobilized its resources. As police
    and defense forces redoubled their law enforcement efforts, the need to easily track any suspicious or
    wanted vehicles became a pressing matter. Manual license plate identification methods were deemed
    ineffective, and a digital solution was required.`,
  ctaText: "Let's work together",
  powered: 'powered by AWS',
  plateNumber: '1DTH752',
  additionalDescription_1: `SoftServe jumped right in. We built a system that automatizes the recognition of vehicle registration
    plates, their verification, and analysis, which contributed to enforcing traffic laws and identifying
    criminals, collaborationists, and terrorists.`,
  additionalDescription_2: `Our solution is now implemented in the Lviv region of Ukraine and processes millions of video frames daily.
    Each month, it helps detect between 80 and 100 wanted vehicles. Apart from contributing to law enforcement,
    it can be easily customized for various business and organizational purposes.`,
};
