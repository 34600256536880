import image_1 from '../../../asset/resource/item-1.png';
import image_2 from '../../../asset/resource/item-2.png';
import image_3 from '../../../asset/resource/item-3.png';
import image_4 from '../../../asset/resource/item-4.png';

import image_1_mobile from '../../../asset/resource/item-1-mobile.png';
import image_2_mobile from '../../../asset/resource/item-2-mobile.png';
import image_3_mobile from '../../../asset/resource/item-3-mobile.png';
import image_4_mobile from '../../../asset/resource/item-4-mobile.png';

export const data = {
  name: 'BENEFITS',
  title: 'WHY CHOOSE CAR PLATE RECOGNITION VIDEO ANALYTICS?',
  items: [
    {
      number: 'Item 1',
      title: 'Scalability',
      description:
        'The solution has the capacity to process videos from numerous cameras across vast distances. The AWS-native architecture, APIs, and tools simplify development and deployment processes.',
    },
    {
      number: 'Item 2',
      title: 'Cost-effectiveness',
      description:
        'AWS-native architecture and serverless technologies allow the reduction of infrastructure costs. The user doesn’t have to maintain and manage their own servers – they only pay for the resources utilized by the AWS services.',
    },
    {
      number: 'Item 3',
      title: 'Reliability',
      description: `The solution provides automatic error handling. Users don't need to manage their own servers or infrastructure as the AWS Lambda platform automatically handles the workload and recovers from failures.`,
    },

    {
      number: 'Item 4',
      title: 'Rich analytics',
      description: `License Plate Recognition Video Analytics seamlessly processes a high volume of video, geolocation, numerical, and other data and visualizes it conveniently.`,
    },
  ],
};

export const desktopImages = [image_1, image_2, image_3, image_4];

export const mobileImages = [image_1_mobile, image_2_mobile, image_3_mobile, image_4_mobile];
