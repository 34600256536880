import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Link as ScrollLink } from 'react-scroll';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import cn from 'classnames';

import { useCheckMobile } from '../../hooks';

import './Header.scss';

import softserve_logo from '../../../asset/resource/SoftServe-logo.png';

const MyPortalComponent = ({ component, root }) => ReactDOM.createPortal(component, root);

export const Header = ({ isMobile, logo, links }) => {
  const [isNavOpen, setNavOpen] = useState(false);
  const is1440px = useCheckMobile({ width: 1440 });
  const is480Mobile = useCheckMobile({ width: 480 });
  const [currentSection, setCurrent] = useState('');

  const portalRoot = document.getElementById('modal-root');

  useEffect(() => {
    const refreshButton = document.getElementById('force-refresh');

    refreshButton.addEventListener('click', () => {
      window.location.reload(true);
    });

    return () => refreshButton.removeEventListener('click', () => window.location.reload(true));
  });

  return (
    <header className="header">
      <div className="header__wrapper">
        <div className="header__event-logo-wrapper">
          <a
            className="footer__url"
            href="https://softserveinc.com"
            target="_blank"
            rel="noopener noreferrer"
            role="link"
          >
            <img src={softserve_logo} className="header__company-logo" alt="logo" />
          </a>
          <p className="header__title">License Plate Recognition</p>
        </div>
        <button id="force-refresh" className="header__force-refresh"></button>
        {isMobile ? (
          <button
            className={cn('header__button', isNavOpen && 'header__button_cross')}
            onClick={() => setNavOpen(!isNavOpen)}
          />
        ) : (
          <nav>
            {links.map(({ id, title, anchor }) => {
              const offset = is1440px ? (anchor === 'header' ? -90 : -20) : anchor === 'contact-us' ? 0 : -90;
              return (
                <ScrollLink
                  smooth
                  spy
                  className="header__navigation-link"
                  activeClass={currentSection === anchor ? 'active' : ''}
                  to={anchor}
                  offset={offset}
                  duration={800}
                  key={id}
                  onSetActive={(to) => setCurrent(to)}
                >
                  {title}
                </ScrollLink>
              );
            })}
          </nav>
        )}
      </div>
      {isNavOpen && (
        <MyPortalComponent
          root={portalRoot}
          component={
            <div className="header__menu-navigation">
              <nav className={cn(`header__menu-navigation-links_${isNavOpen ? 'open' : 'close'}`)}>
                <TransitionGroup component={null}>
                  {links.map(({ id, title, anchor }, index) => (
                    <CSSTransition
                      key={index}
                      appear={true}
                      timeout={150 * index}
                      classNames="header__top-navigation-link"
                    >
                      <ScrollLink
                        smooth
                        spy
                        className="header__top-navigation-link"
                        to={anchor}
                        duration={500}
                        offset={is480Mobile ? -65 : -90}
                        key={id}
                        onClick={() => setNavOpen(false)}
                      >
                        {title}
                      </ScrollLink>
                    </CSSTransition>
                  ))}
                </TransitionGroup>
              </nav>
            </div>
          }
        />
      )}
    </header>
  );
};

export default Header;
