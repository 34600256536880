import image_1 from '../../../asset/resource/upload-example-1.jpeg';
import image_2 from '../../../asset/resource/upload-example-2.png';
import image_3 from '../../../asset/resource/upload-example-3.jpeg';
import cloud from '../../../asset/resource/cloud-icon.png';
import upload_icon from '../../../asset/resource/upload-btn-icon.png';

export const data = {
  name: 'TRY IT OUT',
  sneak: "Here's a sneak peek of how the solution works.",
  title: 'Upload an image of a license plate',
  description: 'The vehicle number will be identified, even if the image is blurry, dark, or angled.',
  examples: {
    title: 'choose one of these photos',
    items: [
      {
        image: image_1,
        ctaText: 'Upload',
      },
      {
        image: image_2,
        ctaText: 'Upload',
      },
      {
        image: image_3,
        ctaText: 'Upload',
      },
    ],
    mobileCtaText: 'Upload this image',
  },
  result: {
    title: 'YOUR RESULT',
    ctaText: 'upload YOUR image',
    ctaRepeatText: 'upload new image',
  },
  loading: {
    title: 'This should only take a moment',
    status: 'uploadING YOUR image',
  },
};

export const cloudImage = cloud;
export const uploadIcon = upload_icon;
