import React from 'react';

import './UploadImageLoader.scss';

const UploadImageLoader = () => {
  return (
    <div className="loading-circle">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default UploadImageLoader;
