import React from 'react';
import { motion } from 'framer-motion';

import { useCheckMobile } from '../../hooks';

import './HowItWorks.scss';

import { data } from './content';

const HowItWorks = () => {
  const isSmallForAnimation = useCheckMobile({ width: 1240 });

  const animationBreakpoint = (offset, sign = '') => (isSmallForAnimation ? sign + offset / 2 : sign + offset);
  return (
    <section id="how-it-works" className="section-hiw">
      <div className="section-hiw__content">
        <p className="section-hiw__name">{data.name}</p>

        <div className="section-hiw__with-center-line">
          <h2 className="section-hiw__title">{data.title}</h2>

          <div className="section-hiw__items">
            {data.items.map((item, idx) => (
              <div key={item.title} className="section-hiw__item-wrapper">
                <div className="section-hiw__icon-wrapper">
                  <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: 1 }}
                    variants={{
                      visible: {
                        width: '125px',
                      },
                      hidden: {
                        width: '0',
                      },
                    }}
                    className={`section-hiw__red-line section-hiw__red-line_${idx % 2 === 0 ? 'left' : 'right'}`}
                  />
                  <img alt="" src={item.image} />
                </div>
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.8 }}
                  variants={{
                    visible: {
                      x: 0,
                    },
                    hidden: {
                      x: idx % 2 === 0 ? animationBreakpoint(400, '-') : animationBreakpoint(400),
                    },
                  }}
                  className={`section-hiw__item section-hiw__item_${idx % 2 === 0 ? 'left' : 'right'}`}
                >
                  <h3 className="section-hiw__item-title">{item.title}</h3>
                  <p className="section-hiw__item-description">{item.description}</p>
                </motion.div>
              </div>
            ))}
          </div>
        </div>
        <div className="section-hiw__bottom-text-wrapper">
          <div className="section-hiw__bottom-text">{data.bottomText}</div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
