import slide_1 from '../../../asset/resource/slide-1.png';
import slide_2 from '../../../asset/resource/slide-2.png';
import slide_3 from '../../../asset/resource/slide-3.png';
import slide_4 from '../../../asset/resource/slide-4.png';
import slide_5 from '../../../asset/resource/slide-5.png';
import slide_6 from '../../../asset/resource/slide-6.png';

export const data = {
  name: 'USE CASES',
  title: 'SEE WHAT YOU CAN ACCOMPLISH',
  items: [
    {
      image: slide_1,
      title: 'TRAFFIC SECURITY',
      description:
        'Monitor traffic to automatically identify and track vehicles. With cameras installed at key intersections or along highways, the license plate information allows for effective law enforcement.',
    },
    {
      image: slide_2,
      title: 'AUTOMATIC TOLL ROAD CHARGES',
      description:
        'Streamline toll collection on paid roads, eliminating the need for toll booths. The system helps charge drivers automatically, enabling smoother traffic flow and reducing traffic jams on highways.',
    },
    {
      image: slide_3,
      title: 'AVERAGE SPEED TRACKING',
      description:
        'The system spots the vehicle at the beginning and at the end at different route points and then it calculates the average car speed, which can be useful for traffic planning and management, as well as for safety considerations on roads and highways.',
    },
    {
      image: slide_4,
      title: 'PARKING MANAGEMENT',
      description:
        'Automate parking processes by identifying vehicles that enter and exit a parking lot. Monitor parking availability in real time, automate payment systems, and track parking violations.',
    },
    {
      image: slide_5,
      title: 'LOGISTICS MANAGEMENT',
      description:
        'Plan, implement, and monitor the efficient flow of goods and maintain an accurate record of arrivals and departures of vehicles by recording their license plates. ',
    },
    {
      image: slide_6,
      title: 'SMART CITY',
      description:
        'Automate and streamline the provision of municipal services. Tracking vehicle numbers helps enhance route planning and improve resource allocation.',
    },
  ],
};
