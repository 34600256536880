import React, { useEffect, useState } from 'react';

import Header from '../../components/Header';
import Hero from '../../components/Hero';
import Footer from '../../components/Footer';
import StyledCarousel from '../../components/Carousel/Carousel';
import HowItWorks from '../../components/HowItWorks/HowItWorks';
import FormSection from '../../components/FormSection/FormSection';
import ImageUpload from '../../components/ImageUpload/ImageUpload';
import { useCheckMobile } from '../../hooks';

import { navLinks } from '../../resources/navigation';

import './Main.scss';
import Benefits from '../../components/Benefits/Benefits';
import ReportSection from '../../components/ReportSection/ReportSection';
import MeetContact from '../../components/MeetContact/MeetContact';
import axios from 'axios';

function Main() {
  const [isSafariBrowser, setIsSafariBrowser] = useState(false);
  const [frameSize, setFrameSize] = useState({ width: 1240, height: 790 });

  const isMobile = useCheckMobile({ width: 1024 });
  const isMobileForHeader = useCheckMobile({ width: 767 });

  useEffect(() => {
    let isSafari = window.navigator.vendor.toLowerCase().indexOf('apple') > -1;
    if (isSafari) setIsSafariBrowser(true);
  }, [isSafariBrowser]);

  const calculateReportSize = () => {
    const safeZone = 0;
    const defaulWidth = 1280 - safeZone;
    const defaultHeight = 780;
    const container = document.getElementsByClassName('upload-section__content')[0];
    const contentWidth = container.clientWidth - safeZone;
    const aspectRatio = defaulWidth / defaultHeight;

    setFrameSize({ width: contentWidth, height: contentWidth / aspectRatio });
  };

  useEffect(() => {
    calculateReportSize();
    window.addEventListener('resize', calculateReportSize);

    return () => {
      window.removeEventListenerEventListener('resize', calculateReportSize);
    };
  }, []);

  return (
    <>
      <div className="main" id="home">
        <Header isMobile={isMobile} links={navLinks} />
      </div>
      <Hero />
      <HowItWorks />
      <StyledCarousel />
      <Benefits />
      <ImageUpload />
      <ReportSection size={frameSize} />
      {/* <MeetContact /> */}
      <div className="main__form-and-footer">
        <FormSection />
        <Footer />
      </div>
    </>
  );
}

export default Main;
